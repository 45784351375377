var app = angular.module('cclApp', ['ngAnimate', 'ngRoute', 'ngMaterial', 'ngMessages', 'duScroll'], function($interpolateProvider) {
	$interpolateProvider.startSymbol('[[');
	$interpolateProvider.endSymbol(']]');
})
.directive('contactMap', function() {
    // directive link function
    var link = function(scope, element, attrs) {
        var map, infoWindow;
        
        // map config
        var mapOptions = {
            center: new google.maps.LatLng(45.5579671, -73.7943035),
            zoom: 13,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false,
            draggable: false
        };
        
        // init the map
        function initMap() {
            if (map === void 0) {
                map = new google.maps.Map(element[0], mapOptions);
            }
            var marker = new google.maps.Marker({
            	position: {
	                lat: 45.5579671,
	                lng: -73.7943035
	            },
                map: map,
                title: 'Clinique Chirurgicale de Laval'
            });
            
            google.maps.event.addListener(marker, 'click', function () {
            	window.open("https://goo.gl/maps/kToTaJw5f2M2");
            });
        }
        
        // show the map and place some markers
        initMap();
    };
    
    return {
        restrict: 'A',
        link: link
    };
});
