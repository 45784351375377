var countries_list = {
	"fr": {
		'AF' : 'Afghanistan',
	    'ZA' : 'Afrique du Sud',
	    'AL' : 'Albanie',
	    'DZ' : 'Algérie',
	    'DE' : 'Allemagne',
	    'AD' : 'Andorre',
	    'AO' : 'Angola',
	    'AI' : 'Anguilla',
	    'AQ' : 'Antarctique',
	    'AG' : 'Antigua-et-Barbuda',
	    'AN' : 'Antilles néerlandaises',
	    'SA' : 'Arabie saoudite',
	    'AR' : 'Argentine',
	    'AM' : 'Arménie',
	    'AW' : 'Aruba',
	    'AU' : 'Australie',
	    'AT' : 'Autriche',
	    'AZ' : 'Azerbaïdjan',
	    'BJ' : 'Bénin',
	    'BS' : 'Bahamas',
	    'BH' : 'Bahreïn',
	    'BD' : 'Bangladesh',
	    'BB' : 'Barbade',
	    'PW' : 'Belau',
	    'BE' : 'Belgique',
	    'BZ' : 'Belize',
	    'BM' : 'Bermudes',
	    'BT' : 'Bhoutan',
	    'BY' : 'Biélorussie',
	    'MM' : 'Birmanie',
	    'BO' : 'Bolivie',
	    'BA' : 'Bosnie-Herzégovine',
	    'BW' : 'Botswana',
	    'BR' : 'Brésil',
	    'BN' : 'Brunei',
	    'BG' : 'Bulgarie',
	    'BF' : 'Burkina Faso',
	    'BI' : 'Burundi',
	    'CI' : 'Côte d\'Ivoire',
	    'KH' : 'Cambodge',
	    'CM' : 'Cameroun',
	    'CA' : 'Canada',
	    'CV' : 'Cap-Vert',
	    'CL' : 'Chili',
	    'CN' : 'Chine',
	    'CY' : 'Chypre',
	    'CO' : 'Colombie',
	    'KM' : 'Comores',
	    'CG' : 'Congo',
	    'KP' : 'Corée du Nord',
	    'KR' : 'Corée du Sud',
	    'CR' : 'Costa Rica',
	    'HR' : 'Croatie',
	    'CU' : 'Cuba',
	    'DK' : 'Danemark',
	    'DJ' : 'Djibouti',
	    'DM' : 'Dominique',
	    'EG' : 'Égypte',
	    'AE' : 'Émirats arabes unis',
	    'EC' : 'Équateur',
	    'ER' : 'Érythrée',
	    'ES' : 'Espagne',
	    'EE' : 'Estonie',
	    'US' : 'États-Unis',
	    'ET' : 'Éthiopie',
	    'FI' : 'Finlande',
	    'FR' : 'France',
	    'GE' : 'Géorgie',
	    'GA' : 'Gabon',
	    'GM' : 'Gambie',
	    'GH' : 'Ghana',
	    'GI' : 'Gibraltar',
	    'GR' : 'Grèce',
	    'GD' : 'Grenade',
	    'GL' : 'Groenland',
	    'GP' : 'Guadeloupe',
	    'GU' : 'Guam',
	    'GT' : 'Guatemala',
	    'GN' : 'Guinée',
	    'GQ' : 'Guinée équatoriale',
	    'GW' : 'Guinée-Bissao',
	    'GY' : 'Guyana',
	    'GF' : 'Guyane française',
	    'HT' : 'Haïti',
	    'HN' : 'Honduras',
	    'HK' : 'Hong Kong',
	    'HU' : 'Hongrie',
	    'BV' : 'Ile Bouvet',
	    'CX' : 'Ile Christmas',
	    'NF' : 'Ile Norfolk',
	    'KY' : 'Iles Cayman',
	    'CK' : 'Iles Cook',
	    'FO' : 'Iles Féroé',
	    'FK' : 'Iles Falkland',
	    'FJ' : 'Iles Fidji',
	    'GS' : 'Iles Géorgie du Sud et Sandwich du Sud',
	    'HM' : 'Iles Heard et McDonald',
	    'MH' : 'Iles Marshall',
	    'PN' : 'Iles Pitcairn',
	    'SB' : 'Iles Salomon',
	    'SJ' : 'Iles Svalbard et Jan Mayen',
	    'TC' : 'Iles Turks-et-Caicos',
	    'VI' : 'Iles Vierges américaines',
	    'VG' : 'Iles Vierges britanniques',
	    'CC' : 'Iles des Cocos (Keeling)',
	    'UM' : 'Iles mineures éloignées des États-Unis',
	    'IN' : 'Inde',
	    'ID' : 'Indonésie',
	    'IR' : 'Iran',
	    'IQ' : 'Iraq',
	    'IE' : 'Irlande',
	    'IS' : 'Islande',
	    'IL' : 'Israël',
	    'IT' : 'Italie',
	    'JM' : 'Jamaïque',
	    'JP' : 'Japon',
	    'JO' : 'Jordanie',
	    'KZ' : 'Kazakhstan',
	    'KE' : 'Kenya',
	    'KG' : 'Kirghizistan',
	    'KI' : 'Kiribati',
	    'KW' : 'Koweït',
	    'LA' : 'Laos',
	    'LS' : 'Lesotho',
	    'LV' : 'Lettonie',
	    'LB' : 'Liban',
	    'LR' : 'Liberia',
	    'LY' : 'Libye',
	    'LI' : 'Liechtenstein',
	    'LT' : 'Lituanie',
	    'LU' : 'Luxembourg',
	    'MO' : 'Macao',
	    'MG' : 'Madagascar',
	    'MY' : 'Malaisie',
	    'MW' : 'Malawi',
	    'MV' : 'Maldives',
	    'ML' : 'Mali',
	    'MT' : 'Malte',
	    'MP' : 'Mariannes du Nord',
	    'MA' : 'Maroc',
	    'MQ' : 'Martinique',
	    'MU' : 'Maurice',
	    'MR' : 'Mauritanie',
	    'YT' : 'Mayotte',
	    'MX' : 'Mexique',
	    'FM' : 'Micronésie',
	    'MD' : 'Moldavie',
	    'MC' : 'Monaco',
	    'MN' : 'Mongolie',
	    'MS' : 'Montserrat',
	    'MZ' : 'Mozambique',
	    'NP' : 'Népal',
	    'NA' : 'Namibie',
	    'NR' : 'Nauru',
	    'NI' : 'Nicaragua',
	    'NE' : 'Niger',
	    'NG' : 'Nigeria',
	    'NU' : 'Nioué',
	    'NO' : 'Norvège',
	    'NC' : 'Nouvelle-Calédonie',
	    'NZ' : 'Nouvelle-Zélande',
	    'OM' : 'Oman',
	    'UG' : 'Ouganda',
	    'UZ' : 'Ouzbékistan',
	    'PE' : 'Pérou',
	    'PK' : 'Pakistan',
	    'PA' : 'Panama',
	    'PG' : 'Papouasie-Nouvelle-Guinée',
	    'PY' : 'Paraguay',
	    'NL' : 'Pays-Bas',
	    'PH' : 'Philippines',
	    'PL' : 'Pologne',
	    'PF' : 'Polynésie française',
	    'PR' : 'Porto Rico',
	    'PT' : 'Portugal',
	    'QA' : 'Qatar',
	    'CF' : 'République centrafricaine',
	    'CD' : 'République démocratique du Congo',
	    'DO' : 'République dominicaine',
	    'CZ' : 'République tchèque',
	    'RE' : 'Réunion',
	    'RO' : 'Roumanie',
	    'UK' : 'Royaume-Uni',
	    'RU' : 'Russie',
	    'RW' : 'Rwanda',
	    'SN' : 'Sénégal',
	    'EH' : 'Sahara occidental',
	    'KN' : 'Saint-Christophe-et-Niévès',
	    'SM' : 'Saint-Marin',
	    'PM' : 'Saint-Pierre-et-Miquelon',
	    'VA' : 'Saint-Siège ',
	    'VC' : 'Saint-Vincent-et-les-Grenadines',
	    'SH' : 'Sainte-Hélène',
	    'LC' : 'Sainte-Lucie',
	    'SV' : 'Salvador',
	    'WS' : 'Samoa',
	    'AS' : 'Samoa américaines',
	    'ST' : 'Sao Tomé-et-Principe',
	    'SC' : 'Seychelles',
	    'SL' : 'Sierra Leone',
	    'SG' : 'Singapour',
	    'SI' : 'Slovénie',
	    'SK' : 'Slovaquie',
	    'SO' : 'Somalie',
	    'SD' : 'Soudan',
	    'LK' : 'Sri Lanka',
	    'SE' : 'Suède',
	    'CH' : 'Suisse',
	    'SR' : 'Suriname',
	    'SZ' : 'Swaziland',
	    'SY' : 'Syrie',
	    'TW' : 'Taïwan',
	    'TJ' : 'Tadjikistan',
	    'TZ' : 'Tanzanie',
	    'TD' : 'Tchad',
	    'TF' : 'Terres australes françaises',
	    'IO' : 'Territoire britannique de l\'Océan Indien',
	    'TH' : 'Thaïlande',
	    'TL' : 'Timor Oriental',
	    'TG' : 'Togo',
	    'TK' : 'Tokélaou',
	    'TO' : 'Tonga',
	    'TT' : 'Trinité-et-Tobago',
	    'TN' : 'Tunisie',
	    'TM' : 'Turkménistan',
	    'TR' : 'Turquie',
	    'TV' : 'Tuvalu',
	    'UA' : 'Ukraine',
	    'UY' : 'Uruguay',
	    'VU' : 'Vanuatu',
	    'VE' : 'Venezuela',
	    'VN' : 'ViÃªt Nam',
	    'WF' : 'Wallis-et-Futuna',
	    'YE' : 'Yémen',
	    'YU' : 'Yougoslavie',
	    'ZM' : 'Zambie',
	    'ZW' : 'Zimbabwe',
	    'MK' : 'Ex-République yougoslave de Macédoine'
	},
	"en": {
		'AF' : 'Afghanistan',
	    'ZA' : 'South Africa',
	    'AL' : 'Albania',
	    'DZ' : 'Algeria',
	    'DE' : 'Germany',
	    'AD' : 'Andorra',
	    'AO' : 'Angola',
	    'AI' : 'Anguilla',
	    'AQ' : 'Antarctica',
	    'AG' : 'Antigua & Barbuda',
	    'AN' : 'Netherlands Antilles',
	    'SA' : 'Saudi Arabia',
	    'AR' : 'Argentina',
	    'AM' : 'Armenia',
	    'AW' : 'Aruba',
	    'AU' : 'Australia',
	    'AT' : 'Austria',
	    'AZ' : 'Azerbaijan',
	    'BJ' : 'Benin',
	    'BS' : 'Bahamas, The',
	    'BH' : 'Bahrain',
	    'BD' : 'Bangladesh',
	    'BB' : 'Barbados',
	    'PW' : 'Palau',
	    'BE' : 'Belgium',
	    'BZ' : 'Belize',
	    'BM' : 'Bermuda',
	    'BT' : 'Bhutan',
	    'BY' : 'Belarus',
	    'MM' : 'Myanmar (ex-Burma)',
	    'BO' : 'Bolivia',
	    'BA' : 'Bosnia and Herzegovina',
	    'BW' : 'Botswana',
	    'BR' : 'Brazil',
	    'BN' : 'Brunei Darussalam',
	    'BG' : 'Bulgaria',
	    'BF' : 'Burkina Faso',
	    'BI' : 'Burundi',
	    'CI' : 'Ivory Coast',
	    'KH' : 'Cambodia',
	    'CM' : 'Cameroon',
	    'CA' : 'Canada',
	    'CV' : 'Cape Verde',
	    'CL' : 'Chile',
	    'CN' : 'China',
	    'CY' : 'Cyprus',
	    'CO' : 'Colombia',
	    'KM' : 'Comoros',
	    'CG' : 'Congo',
	    'KP' : 'Korea, Demo. People\'s Rep. of',
	    'KR' : 'Korea, (South) Republic of',
	    'CR' : 'Costa Rica',
	    'HR' : 'Croatia',
	    'CU' : 'Cuba',
	    'DK' : 'Denmark',
	    'DJ' : 'Djibouti',
	    'DM' : 'Dominica',
	    'EG' : 'Egypt',
	    'AE' : 'United Arab Emirates',
	    'EC' : 'Ecuador',
	    'ER' : 'Eritrea',
	    'ES' : 'Spain',
	    'EE' : 'Estonia',
	    'US' : 'United States',
	    'ET' : 'Ethiopia',
	    'FI' : 'Finland',
	    'FR' : 'France',
	    'GE' : 'Georgia',
	    'GA' : 'Gabon',
	    'GM' : 'Gambia, the',
	    'GH' : 'Ghana',
	    'GI' : 'Gibraltar',
	    'GR' : 'Greece',
	    'GD' : 'Grenada',
	    'GL' : 'Greenland',
	    'GP' : 'Guinea, Equatorial',
	    'GU' : 'Guam',
	    'GT' : 'Guatemala',
	    'GN' : 'Guinea',
	    'GQ' : 'Equatorial Guinea',
	    'GW' : 'Guinea-Bissau',
	    'GY' : 'Guyana',
	    'GF' : 'Guiana, French',
	    'HT' : 'Haiti',
	    'HN' : 'Honduras',
	    'HK' : 'Hong Kong, (China)',
	    'HU' : 'Hungary',
	    'BV' : 'Bouvet Island',
	    'CX' : 'Christmas Island',
	    'NF' : 'Norfolk Island',
	    'KY' : 'Cayman Islands',
	    'CK' : 'Cook Islands',
	    'FO' : 'Faroe Islands',
	    'FK' : 'Falkland Islands (Malvinas)',
	    'FJ' : 'Fiji',
	    'GS' : 'S. Georgia and S. Sandwich Is.',
	    'HM' : 'Heard and McDonald Islands',
	    'MH' : 'Marshall Islands',
	    'PN' : 'Pitcairn Island',
	    'SB' : 'Solomon Islands',
	    'SJ' : 'Svalbard and Jan Mayen Islands',
	    'TC' : 'Turks and Caicos Islands',
	    'VI' : 'Virgin Islands, U.S.',
	    'VG' : 'Virgin Islands, British',
	    'CC' : 'Cocos (Keeling) Islands',
	    'UM' : 'US Minor Outlying Islands',
	    'IN' : 'India',
	    'ID' : 'Indonesia',
	    'IR' : 'Iran, Islamic Republic of',
	    'IQ' : 'Iraq',
	    'IE' : 'Ireland',
	    'IS' : 'Iceland',
	    'IL' : 'Israel',
	    'IT' : 'Italy',
	    'JM' : 'Jamaica',
	    'JP' : 'Japan',
	    'JO' : 'Jordan',
	    'KZ' : 'Kazakhstan',
	    'KE' : 'Kenya',
	    'KG' : 'Kyrgyzstan',
	    'KI' : 'Kiribati',
	    'KW' : 'Kuwait',
	    'LA' : 'Lao People\'s Democratic Republic',
	    'LS' : 'Lesotho',
	    'LV' : 'Latvia',
	    'LB' : 'Lebanon',
	    'LR' : 'Liberia',
	    'LY' : 'Libyan Arab Jamahiriya',
	    'LI' : 'Liechtenstein',
	    'LT' : 'Lithuania',
	    'LU' : 'Luxembourg',
	    'MO' : 'Macao, (China)',
	    'MG' : 'Madagascar',
	    'MY' : 'Malaysia',
	    'MW' : 'Malawi',
	    'MV' : 'Maldives',
	    'ML' : 'Mali',
	    'MT' : 'Malta',
	    'MP' : 'Northern Mariana Islands',
	    'MA' : 'Morocco',
	    'MQ' : 'Martinique',
	    'MU' : 'Mauritius',
	    'MR' : 'Mauritania',
	    'YT' : 'Mayotte',
	    'MX' : 'Mexico',
	    'FM' : 'Micronesia, Federated States of',
	    'MD' : 'Moldova, Republic of',
	    'MC' : 'Monaco',
	    'MN' : 'Mongolia',
	    'MS' : 'Montserrat',
	    'MZ' : 'Mozambique',
	    'NP' : 'Nepal',
	    'NA' : 'Namibia',
	    'NR' : 'Nauru',
	    'NI' : 'Nicaragua',
	    'NE' : 'Niger',
	    'NG' : 'Nigeria',
	    'NU' : 'Niue',
	    'NO' : 'Norway',
	    'NC' : 'New Caledonia',
	    'NZ' : 'New Zealand',
	    'OM' : 'Oman',
	    'UG' : 'Uganda',
	    'UZ' : 'Uzbekistan',
	    'PE' : 'Peru',
	    'PK' : 'Pakistan',
	    'PA' : 'Panama',
	    'PG' : 'Papua New Guinea',
	    'PY' : 'Paraguay',
	    'NL' : 'Netherlands',
	    'PH' : 'Philippines',
	    'PL' : 'Poland',
	    'PF' : 'French Polynesia',
	    'PR' : 'Puerto Rico',
	    'PT' : 'Portugal',
	    'QA' : 'Qatar',
	    'CF' : 'Central African Republic',
	    'CD' : 'Congo, Democratic Rep. of the',
	    'DO' : 'Dominican Republic',
	    'CZ' : 'Czech Republic',
	    'RE' : 'Reunion',
	    'RO' : 'Romania',
	    'UK' : 'United Kingdom',
	    'RU' : 'Russia (Russian Federation)',
	    'RW' : 'Rwanda',
	    'SN' : 'Senegal',
	    'EH' : 'Western Sahara',
	    'KN' : 'Saint Kitts and Nevis',
	    'SM' : 'San Marino',
	    'PM' : 'Saint Pierre and Miquelon',
	    'VA' : 'Vatican City State (Holy See)',
	    'VC' : 'Saint Vincent and the Grenadines',
	    'SH' : 'Saint Helena',
	    'LC' : 'Saint Lucia',
	    'SV' : 'El Salvador',
	    'WS' : 'Samoa',
	    'AS' : 'American Samoa',
	    'ST' : 'Sao Tome and Principe',
	    'SC' : 'Seychelles',
	    'SL' : 'Sierra Leone',
	    'SG' : 'Singapore',
	    'SI' : 'Slovenia',
	    'SK' : 'Slovakia',
	    'SO' : 'Somalia',
	    'SD' : 'Sudan',
	    'LK' : 'Sri Lanka (ex-Ceilan)',
	    'SE' : 'Sweden',
	    'CH' : 'Switzerland',
	    'SR' : 'Suriname',
	    'SZ' : 'Swaziland',
	    'SY' : 'Syrian Arab Republic',
	    'TW' : 'Taiwan',
	    'TJ' : 'Tajikistan',
	    'TZ' : 'Tanzania, United Republic of',
	    'TD' : 'Chad',
	    'TF' : 'French Southern Territories - TF',
	    'IO' : 'British Indian Ocean Territory',
	    'TH' : 'Thailand',
	    'TL' : 'Timor-Leste (East Timor)',
	    'TG' : 'Togo',
	    'TK' : 'Tokelau',
	    'TO' : 'Tonga',
	    'TT' : 'Trinidad & Tobago',
	    'TN' : 'Tunisia',
	    'TM' : 'Turkmenistan',
	    'TR' : 'Turkey',
	    'TV' : 'Tuvalu',
	    'UA' : 'Ukraine',
	    'UY' : 'Uruguay',
	    'VU' : 'Vanuatu',
	    'VE' : 'Venezuela',
	    'VN' : 'Viet Nam',
	    'WF' : 'Wallis and Futuna',
	    'YE' : 'Yemen',
	    'YU' : 'Saint Pierre and Miquelon',
	    'ZM' : 'Zambia',
	    'ZW' : 'Zimbabwe',
	    'MK' : 'Macedonia, TFYR'
	}
}