app.controller('ContactController', ['$scope', function($scope){
    /*** FORMULAIRE PRENDRE UN RENDEZ-VOUS ***/
    // Blessures
    $scope.$watch('[blessureGenou, blessureEpaule, blessureHanche, blessurePoignetMain, blessureCoude]', function(){
        if($scope.blessureGenou) {
            $scope.blessure = $scope.blessureGenou;
        }
        else if($scope.blessureEpaule) {
            $scope.blessure = $scope.blessureEpaule;
        }
        else if($scope.blessureHanche) {
            $scope.blessure = $scope.blessureHanche;
        }
        else if($scope.blessurePoignetMain) {
            $scope.blessure = $scope.blessurePoignetMain;
        }
        else if($scope.blessureCoude) {
            $scope.blessure = $scope.blessureCoude;
        }
        else {
            $scope.blessure = '';
        }
    });


    // Birthdate
    $scope.$watch('[jourNaissance, moisNaissance, anneeNaissance]', function(){
        if($scope.jourNaissance && $scope.moisNaissance && $scope.anneeNaissance) {
            $scope.dateNaissance = $scope.anneeNaissance + '-' + $scope.moisNaissance + '-' + $scope.jourNaissance;
        }
    });


	// Autocomplete pour les pays
	$scope.countries = [];
	angular.forEach(countries_list[appLocale], function(country, key){
		var temp = {
			value: key,
        	display: country
        };
        $scope.countries.push(temp);
	});

    $scope.getMatches = function(search) {
        var results = search ? $scope.countries.filter(createFilterFor(search)) : [];
        return results;
    }

    if(oldPays) {
        angular.forEach(countries_list[appLocale], function(country, key){
            if (key == oldPays) {
                $scope.searchText = country;
            }
        });
        $scope.countryValue = oldPays;
    }

    // Filter pour la recherche autocomplete
    function createFilterFor(query) {
        var lowercaseQuery = angular.lowercase(query);
        
        return function filterFn(element) {
            return (angular.lowercase(element.display).indexOf(lowercaseQuery) !== -1);
        };
    };

    // Update la liste des catégories selon le search
    $scope.search = function() {
        if($scope.selectedItem) {
            $scope.countryValue = $scope.selectedItem.value;
        }
    }


    // Pré-remplissage du formulaire selon l'url
    var url = window.location.href;
    var params = url.split('?referer=');
    if(params[1]){
        var param = params[1].split('#');
        $scope.specialiteUrl = param[0];
    }

    // Scroll au formulaire à l'ouverture d'un form
    $scope.scrollToForm = function(){
        var form = angular.element(document.getElementById('form'));
        angular.element(document).scrollToElement(form, 20, 300);
    }
}]);
