app.controller('GeneralController', ['$scope', '$timeout', function($scope, $timeout){
	
	/*** MENU ***/
	$scope.openSubmenu = function(event){
		if(event.target.classList.contains('orthopedie')) {
			$scope.showInnovMenu = false;
			$scope.showOrthoMenu = !$scope.showOrthoMenu;
		}
		else if(event.target.classList.contains('innovations')) {
			$scope.showOrthoMenu = false;
			$scope.showInnovMenu = !$scope.showInnovMenu;
		}
	}


	/*** CHANGE BANNER TOP HOME BACKGROUND RANDOM ***/
	var array = ['/images/banners/genou.jpg', '/images/banners/epaule.jpg', '/images/banners/hanche.jpg', '/images/banners/poignet-main.jpg', '/images/banners/cheville-pied.jpg', '/images/banners/coude.jpg'];
    function shuffle(array) {
        var currentIndex = array.length;
        var temporaryValue;
        var randomIndex;
        if(0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            temporaryValue = array[randomIndex];
        }
        return temporaryValue;
    }
    var shuffled_images = shuffle(array);
    $scope.imageHome = shuffled_images;


    /*** SLIDER HOMEPAGE TESTIMONIALS ***/
    $scope.imagesSlider = [{
		background: "url('../images/banners/temoignage-01-accueil.jpg')",
	    text: Lang.get('temoignages.temoignage_1'),
	    author: Lang.get('temoignages.temoignage_1_author')
	}, {
	    background: "url('../images/banners/temoignage-02-accueil.jpg')",
	    text: Lang.get('temoignages.temoignage_2'),
	    author: Lang.get('temoignages.temoignage_2_author')
	}, {
	    background: "url('../images/banners/temoignage-02-accueil.jpg')",
	    text: Lang.get('temoignages.temoignage_3'),
	    author: Lang.get('temoignages.temoignage_3_author')
	}, {
	    background: "url('../images/banners/temoignage-02-accueil.jpg')",
	    text: Lang.get('temoignages.temoignage_4'),
	    author: Lang.get('temoignages.temoignage_4_author')
	}, {
	    background: "url('../images/banners/temoignage-02-accueil.jpg')",
	    text: Lang.get('temoignages.temoignage_5'),
	    author: Lang.get('temoignages.temoignage_5_author')
	}];

	// Nav
	$scope.currentIndex = 0; // Initially the index is at the first image
	$scope.nextSlide = function() {
		$scope.currentIndex < $scope.imagesSlider.length - 1 ? $scope.currentIndex++ : $scope.currentIndex = 0;
	};

	$scope.prevSlide = function() {
		$scope.currentIndex > 0 ? $scope.currentIndex-- : $scope.currentIndex = $scope.imagesSlider.length - 1;
	};

	// Visibility
	$scope.$watch('currentIndex', function() {
		$scope.imagesSlider.forEach(function(image) {
	    	image.visible = false; // make every image invisible
		});

		$scope.imagesSlider[$scope.currentIndex].visible = true; // make the current image visible
	});

	// Loop Auto
	var timer;
	var sliderFunc = function() {
		timer = $timeout(function() {
	    	$scope.nextSlide();
	    	timer = $timeout(sliderFunc, 5000);
	  	}, 3000);
	};
	sliderFunc();
	$scope.$on('$destroy', function() {
		$timeout.cancel(timer); // when the scope is getting destroyed, cancel the timer
	});


	/* REVEAL */
	window.sr = ScrollReveal();
	sr.reveal('.overflow img', {
		distance: '50%',
		scale: 1,
		duration: 900,
	});

}]);